/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 998;
  }
  
  /* Modal Content */
  .modal {
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    width: 360px;
    max-width: 90%;
    max-height: 90%;
    overflow-y: auto;
    position: relative;
    z-index: 999;

    &.medium {
      width: 50%
    }

    &.large {
      width: 90%
    }
  }
  
  /* Modal Close Button */
  .modal-close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    font-size: 24px;
    font-weight: bold;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
  }

  .modal-footer {
    text-align:center;
    .modal-close-button {
      margin: 0 auto;
    } 
  }
  

  /* Modal Header */
  .modal-header {
    font-weight:700;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  /* Modal Content */
  .modal-content {
    padding: 20px;
    text-align: center;
  }