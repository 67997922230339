/* =================================== */
/*  Navbar component                    */
/* =================================== */

.tab-navigation__tab-container {
    border-bottom: 1px solid #f7f2ec;

    @media screen and (min-width: 40em) {
        border-bottom: 0;
    }
}

.tab-navigation__tab {
    display: block;
    padding: 7px 22px;
    background-color: $color-grey-almost-white; // TODO original color #f9f9f9 (check if we need to att this color to the pallete); 
    color: $color-grey-dark; // TODO original color #777777; //$color-gray-4; (check if we need to att this color to the pallete); 

    @media screen and (min-width: 40em) {
        float: left;
        padding-top: 12px;
        padding-bottom: 12px;
    }

    &:not(:first-child) {
        margin-left: 2px;
    }

    &:hover {
        background-color: $color-grey-almost-white;
    }

    &.is-active {
        background-color: $color-white;
        color: $color-grey-darkest;
        cursor: default;
    }
}

.navbar {
    .nav-link {
        cursor: pointer;
        user-select: none;

        &:hover {
            color: black;
            background-color: $color-white;
        }

        &.is-active {
            font-weight: bold;
        }
    }
}