html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    font-family: LMSans, sans-serif;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    padding: 0;
    background-color: #f7f2ec;

    .app-wrapper {
        margin: 0 auto;
        width: 100%;
        min-height: 100vh;
        justify-content: space-between;

        .base-wrapper {
            background-color: #fff;
            border-bottom: 2px solid #f7f2ec;
            line-height: 1.5;
            display: inline-block;
            width: 100%;

            .base-inner {
                margin: 0 !important;
            }
            
        }
    }
}