/* =================================== */
/*  Generic pallete                    */
/* =================================== */

/* ===== Black, Greys, white ===== */
$color-black: rgba(0, 0, 0);
$color-grey-darkest: rgb(61, 59, 60);
$color-grey-dark: rgb(97, 97, 97);
$color-grey: rgb(185, 185, 185);
$color-grey-light: rgb(206, 206, 206);
$color-grey-lightest: rgb(224, 224, 224);
$color-grey-ultra-light: rgb(235, 235, 235);
$color-grey-almost-white: rgb(244, 244, 244);
$color-white: rgba(255, 255, 255);

/* =================================== */
/*  Lantmannen (GWF) Palette          */
/* =================================== */

/* 
    https://identitymanual.lantmannen.com/start/grafiska-byggstenar/farg/
    All of Lantmännen’s communication is based on the green colour scale, 
    which consists of a green primary colour and five supporting identity colours.
*/

/* ===== Color Greens Primary and Supporting Identyties ===== */
$color-green-primary: rgb(35, 167, 63);
$color-green-supporting-identity-1: rgb(215, 225, 140);
$color-green-supporting-identity-2: rgb(180, 205, 115);
$color-green-supporting-identity-3: rgb(145, 185, 90);
$color-green-supporting-identity-4: rgb(65, 140, 65);
$color-green-supporting-identity-5: rgb(30, 110, 55);

/** 
    Colours for information graphics
    This colour scale may only be used for information graphics, not in layouts 
    as primary color.
*/

/* ===== Color Purple information graphics  ===== */
$color-purple-information-1: rgb(55, 50, 115);
$color-purple-information-2: rgb(80, 85, 150);
$color-purple-information-3: rgb(120, 120, 180);
$color-purple-information-4: rgb(180, 175, 215);

/* ===== Color blue information graphics ===== */
$color-blue-information-1: rgb(55, 90, 135);
$color-blue-information-2: rgb(75, 125, 185);
$color-blue-information-3: rgb(125, 175, 226);
$color-blue-information-4: rgb(175, 205, 240);

/* ===== Color orange information graphics ===== */
$color-orange-information-1: rgb(150, 79, 39);
$color-orange-information-2: rgb(205, 130, 35);
$color-orange-information-3: rgb(205, 130, 35);
$color-orange-information-4: rgb(250, 230, 140);

/* ===== Color red information graphics ===== */
$color-red-information-1: rgb(125, 35, 40);
$color-red-information-2: rgb(170, 55, 45);
$color-red-information-3: rgb(205, 100, 85);
$color-red-information-4: rgb(225, 165, 145);

/* ===== Color brown information graphics ===== */
$color-brown-information-1: rgb(85, 70, 45);
$color-brown-information-2: rgb(125, 95, 65);
$color-brown-information-3: rgb(175, 150, 125);
$color-brown-information-4: rgb(215, 205, 185);

/* ===== Functional colors ===== */
/**
    The green colour scale is complemented by three colours with specific functions.

    A blue colour kick that is used e.g. to accentuate and highlight a graphic element. 
    It should not be used as a background colour or overpower the green in a layout.
    A sand coloured background colour for e.g. solid colour areas.
    A grey text colour that can replace 100% black text where the latter is perceived 
    as contrasting too strongly with the background.
**/
$color-functional-blue: rgb(73, 159, 216);
$color-functional-grey: rgb(246, 242, 236);
$color-functional-dark-grey: rgb(61, 59, 60);

/* ===== Text colors ===== */
$text-color-paragraph: $color-black;
$text-color-headings: $color-functional-dark-grey;