// ***************************************************************
// *** Default settings
// ***************************************************************
//  Base sizing unit

$base: var(--base, 8px) !default;
$em-base: var(--em-base, 1em) !default;

/* ===== Font & Headings  ===== */
$base-font-size: 16px;
$base-line-height: 24px;

$font-sans: 'LMSans', sans-serif;
$font-serif: 'LMSerif', serif;

$heading-size-1: 48px;
$heading-size-2: 36px;
$heading-size-3: 30px;
$heading-size-4: 24px;
$heading-size-5: 20px;
$heading-size-6: 16px;

/* ===== Paddings / Margins  ===== */
$spacers: (
    "0": 0,
    "05": calc(#{$base}/2),
    "1": calc(#{$base} * 1),
    "1half": calc(#{$base} * 1.5),
    "2": calc(#{$base} * 2),
    "3": calc(#{$base} * 3),
    "4": calc(#{$base} * 4),
    "6": calc(#{$base} * 6),
    "7": calc(#{$base} * 7),
    "8": calc(#{$base} * 8),
    "5": calc(#{$base} * 5),
    "9": calc(#{$base} * 9),
    "10": calc(#{$base} * 10),
    "11": calc(#{$base} * 11),
    "12": calc(#{$base} * 12),
    "13": calc(#{$base} * 13),
    "14": calc(#{$base} * 14),
    "15": calc(#{$base} * 15),
    "16": calc(#{$base} * 16),
    "17": calc(#{$base} * 17),
    "18": calc(#{$base} * 18),
    "19": calc(#{$base} * 19),
    "20": calc(#{$base} * 20),
    "21": calc(#{$base} * 21),
    "22": calc(#{$base} * 22),
    "23": calc(#{$base} * 23),
    "24": calc(#{$base} * 24),
    "auto": "auto"
) !default;

/* ===== Sizes in percent  ===== */
$sizings: (
    "25%",
    "50%",
    "75%",
    "100%",
    "auto"
) !default;

/* ===== Breakpoints  ===== */
$breakpoints-map: (
    xs: 0,
    sm: 32.5em, // 520px
    md: 56em, // 896px
    lg: 78em // 1248px
) !default;
