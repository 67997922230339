.calendar {
  width: 320px;
  min-height: 332px;
  border: 1px solid $color-grey-light;
  border-radius: 3px;
  position: relative;
  font-size:13px;
  background-color: $color-white;
  
  header {
    align-items: center;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }

  .btn-previous {
    margin-left: auto;
  }

  h5 {
    flex-grow: 5;
    text-align: center;
  }

  .btn-next {
    margin-right: auto;
  }

  .calendar-body,
  .days-of-week {
    padding: 0 10px 10px 10px;
  }

  .today {
    background-color: $color-brown-information-4;
    color: $color-black;
  }

  .week {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    border-bottom: 1px solid $color-grey-lightest;

    &:last-child {
      border-bottom: none;
    }

  }

  .days-of-week {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    color: $color-grey-dark;
  }

  .day,
  .empty-day,
  .days-of-week div,
  .selected-day {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    cursor: auto;
  }

  .day {
    cursor: pointer;

    &:hover,
    .selected-day {
      background-color: $color-green-primary;
      color: $color-white;
    }
  }

  .empty-day {
    color: $color-grey;
  }

  .selected-day {
    background-color: $color-green-supporting-identity-5;
    color: #fff;
    cursor: pointer;

    &:hover,
    .selected-day {
      background-color: $color-green-primary;
      color: $color-white;
    }
  }


}