/* =================================== */
/*  Dropdown component                 */
/* =================================== */
.dropdown-wrapper {
    position: relative;
    width: 100%;

    .dropdown-input {
        margin-top: 0px;
    }

    .reset-filter {
        width:20px !important;
        right:0;
        position: absolute;
        cursor: pointer;
        z-index:10;
        i {
            color: $color-red-information-1;
        }
        
    }

    /* ===== Label ===== */
    label {
        position: relative;
        bottom: 5px;
        left: 8px;
        color: rgb(97, 97, 97);
        margin-bottom: calc(var(--base, 8px) / 2);
        letter-spacing: 0.05rem;
    }

    /* ===== Header ===== */
    .dropdown-header {
        @extend .field-basic;

        /* ===== Error border ===== */
        &.validation-error {
            box-shadow: 0px 4px 0px -2px $color-red-information-2;
        }

        &.validation-touched {
            box-shadow: 0px 4px 0px -2px $color-green-supporting-identity-4;
        }

        /* ===== Button Component ===== */
        button {
            border: none;
            background-color: $color-white;
            width: 100%;
            height: 55px;
            padding: 11px 15px !important;
            @extend .field-hover;

            /* ===== Icon Component ===== */
            i {
                width: 10px;
                margin-right: 17px;
            }
        }
    }

    /* ================================================= */
    /*  List Component                                   */
    /* ================================================= */
    .dropdown-list {
        position: absolute;
        margin-top: -1px;
        z-index: 9999;
        overflow: auto;
        max-height: 350px;

        @extend .field-basic;

        ul {
            border-top: 1px solid $color-grey-light;
        }

        /* ===== Button Component ===== */
        button {
            padding: 8px 16px;
            background-color: $color-white;
            @extend .field-hover;

            /* ===== Icon Component ===== */
            i {
                width: 10px;
                margin-right: 15px;
            }
        }
    }

    /* ===== Error message ===== */
    .error-message {
        display: none;
        background-color: rgba(0, 0, 0, 0.04);
        padding: 3px 20px;
        color: $color-red-information-2;
        width: 100%;
        position: relative;

        span {
            position: relative;
            padding-left: 6px;
            font-weight: 600;
            color: $color-red-information-2;
            letter-spacing: 0;
        }

        i {
            color: $color-red-information-2;
            position: absolute;
            top: 8px;
            left: 6px;
        }
    }

    .error-message.active {
        display: block;
        padding-left: 20px;
    }
}
