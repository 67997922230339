/* ===== Basic form field layout  ===== */
.field-basic {
    
    width: 100%;
    border-radius: 2px;
    outline: none;
    box-shadow: none;
    
    border: 1px solid $color-grey !important;
    &:hover {
        border: 1px solid $color-green-primary;
    }
}

/* ===== Hover state ==== */
.field-hover {
    
    &:hover,
    :focus,
    :active {
        background-color: $color-grey-almost-white;
        color: $color-grey-dark;
      
        i {
            color: $color-green-primary;
        }
    }
}

label {
    display:block;
}

button, input, select, textarea {
    margin: 0;
    font: inherit;
}
