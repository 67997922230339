table {
  width: 100%;
  font-family: 'LMSans';
  border-spacing: 0;
  border-collapse: collapse;
  margin:0px;
  padding:0px;

  thead {
    width:100%;
    background: $color-green-supporting-identity-5;
    border: none;
    position: sticky;
    top: -1px;
    z-index: 10;
    
    th {
      text-align: left;
      cursor: pointer;
      padding: 0.6em;
      white-space: nowrap;
      font-weight: normal;

      &.sorting {
        &:hover {
          background: $color-green-primary;
        }
      }
      
      color: $color-white;

      button {
        width:100%;

        i {
          float:left !important;
          padding-right:5px;
          padding-left: 0 !important;
        }
      }

      .content {
        user-select: none;
      }

      &:first-child {
        padding-left: 0.8em;
        text-align: left;
      }

    }
  }

  tbody {
    background-color: $color-white;
    position: relative;
    z-index: 9;
    margin-top:1px;
    
    td {
      text-align: left;
      padding: 0.4em 0.6em;
      border-bottom: 1px solid $color-grey-light;

      &:first-child {
        padding-left: 0.7em;
      }

      span {
        width: 24px;
        height: 24px;
      }

      input {
        border:none !important;
       
        &:disabled {
          background-color: $color-white;
          border:none;
        }  
      }

      input[type="text"] {
        min-width: 170px;
      }
    }

    tr {
      white-space: nowrap;
      border: none;

      &:hover {
        border-bottom: 1px solid $color-grey-dark; 
      }

      &.clickable-row {
        cursor: pointer;
      }

      &.selected {
        background: $color-grey-lightest !important;
      }

      transition: 0.3s ease-in;
      transition-property: clip;
      transition-duration: 0.5s;
      transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
      clip: rect(auto, auto, 0, auto);

    }
  }

}

// Loop for setting nth child css
// Use: Tables with a sticky column (available options sticky-col-1 to sticky-col-10).
// Example: <table class="sticky-col-3"></table>
@for $i from 1 through 10 {
	.sticky-col-#{$i} {
		tr.selected {
			td:nth-child(#{$i}) {
				position: sticky;
				background: rgba(0, 0, 0, 0.6) !important;
				color:#fff;
				left:0px;
				text-align:center;
				white-space: nowrap;
			}
		}	
	}
}	

.table-wrapper {
  overflow-x: visible;
  max-height: calc(90vh - 233px);
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
}	