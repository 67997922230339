.app {
    margin: 15px;
    position:relative;
}

.checkbox i,
.radio i {
    left: unset;
    right: unset;
}

.section-container {
    padding-bottom: 24px;

    &.center {
        margin: 0 auto;
        text-align: center;
    }

    &.right {
        float: right;
        margin-right: 10px;
    }

    &.padding-top {
        padding-top: 24px;
    }

}

/* TODO #28784: Investigate - Remove when problem is found */
.checkbox {
    i {
        right: -11px;
    }
}

.checkbox-container-space {
    margin-top: 28px;
    margin-bottom: 33px;
}

// Hide Id column.
table td:first-child,
table th:first-child {
    display: none;
}


table.infoContainer td {
    display: table-cell;
}

table.displayAllCells td:first-child,
table.displayAllCells th:first-child {
    display: block !important;
    
}

table.displayAllCells.breakRows td {
    white-space: pre-line;
}

// Set column width for Facility
table td:nth-child(4) {
    min-width: 215px;
}

// Set Sorting button width
table th button {
    white-space: nowrap;
    min-width: 140px;
}

.infoContainer {
    margin-bottom: 20px;
    background-color: #ededed;
    padding: 20px;

    td,
    tr {
        background-color: #ededed;
    }
}

.mandatory {
    padding-bottom: 20px;
    font-weight: bold;
}

.confirmDelete {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
}

.remove-padding {
    padding: 0;
}

/* Padding top */
@for $i from 1 through 30 {
    .padding-top-#{$i} {
        padding-top: #{$i}px;
    }
}

/* Padding bottom */
@for $i from 1 through 30 {
    .padding-bottom-#{$i} {
        padding-bottom: #{$i}px;
    }
}

/* Margin top */
@for $i from 1 through 30 {
    .margin-top-#{$i} {
        margin-top: #{$i}px;
    }
}

/* Margin bottom */
@for $i from 1 through 30 {
    .margin-bottom-#{$i} {
        margin-bottom: #{$i}px;
    }
}

.environment-text {
    color: #aa372d;
    font-size: 22px;
    border: 1px solid;
    padding: 0px 12px 2px;
    border-radius: 10px;
    font-weight: 400;
    margin-bottom: 27px;
    vertical-align: middle
}


/* Adjustments TODO: Set extra size in Storybook */
.prev-group {
    position: relative;

    .prev-button {
        position: absolute;
        right: 0;
        bottom: 0;
        min-width: 120px;
    }

    .prev-dropdown {
        position: absolute;
        right: 130px;
        bottom: 0;

        .dropdown-wrapper {
            width: 350px;

            .dropdown-header button {
                padding: 7px 15px !important;
            }

            .dropdown-header button,
            .dropdown-header input {
                height: 47px;
            }
        }
    }

    /* Mobile tablet */
    @media screen and (max-width: 840px) {
        position: relative;

        .prev-button {
            position: unset;
            margin-top: 10px;
        }

        .prev-dropdown {
            position: unset;

            .dropdown-wrapper {
                width: 100%;
            }
        }
    }
}
