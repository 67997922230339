/* =================================== */
/*  Input component                    */
/* =================================== */

input {
    font-family: 'LMSans';
    @extend .field-basic;

    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
    padding: 11px 15px;
    text-align: left;
    line-height: 2;
    font-size: 16px;
    width: 100%;
    margin-top: 4px;

    @extend .field-hover;

    &::placeholder {
        font-weight: normal;
        appearance: textfield;
        color: $color-grey-light;
    }

    &:focus,
    &:focus {
        animation: glow 300ms ease-out;
        animation-fill-mode: forwards;
    }

    &:disabled {
        background-color: $color-grey-light;
        border: 1px solid $color-grey-light;
        color: $color-grey-dark;
        cursor: not-allowed;
    }

    &.validation-error {
        box-shadow: 0px -4px 0px -2px $color-red-information-2 inset;
    }

    &.validation-touched {
        box-shadow: 0px -4px 0px -2px $color-green-supporting-identity-4 inset;
    }
}

.label {
    position: relative;
    bottom: 6px;
    left: 8px;
}

/* =================================== */
/*  Checkbox & Input component         */
/* =================================== */
.checkbox,
.radio {
    position: relative;
    cursor: pointer;

    @extend .field-basic;
    @extend .field-hover;

    margin-right: 10px;
    vertical-align: baseline;
    float: left;
    width: 25px;
    height: 25px;

    &:hover {
        border: 1px solid $color-green-primary;
    }

    &:checked {
        animation: pop 300ms ease-out;
        animation-fill-mode: forwards;
    }

    i {
        position: absolute;
        top: 50%;
        left: 50%;
        right: 50%;
        margin-left: -10px;
        margin-bottom: -2px;
        transform: translate(-50%, -50%);
        font-size: 25px;
        color: $color-green-supporting-identity-4;
        padding-left: 5px;

        &:not(:focus) {
            background: none;
        }

        &:hover {
            color: $color-green-primary;
        }
    }
}

.radio {
    border-radius: 50%;

    i {
        margin-left: -10px;
        font-size: 24px;
    }
}

.checkbox-label,
.radio-label {
    position: relative;
    top: 2px;
}

td {
    &:focus-within {
        background: $color-grey-almost-white;
    }

    input {
        font-family: 'LMSans';
        margin: 0;
        border: none;
        display: inline-block;
        font-weight: normal;
        overflow: auto;
        white-space: nowrap;
        position: relative;
        padding: 0;
        text-align: left;

        width: 100%;
        &:focus,
        &:hover {
            border: none;
            background: none !important;
        }
    }
}

.input {
    position: relative;

    .error-message {
        display: none;
        background-color: rgba(0, 0, 0, 0.04);
        padding: 3px 20px;
        color: $color-red-information-2;
        width: 100%;
        position: relative;

        span {
            position: relative;
            padding-left: 6px;
            font-weight: 600;
            color: $color-red-information-2;
            letter-spacing: 0;
        }

        i {
            color: $color-red-information-2;
            position: absolute;
            top: 8px;
            left: 6px;
        }
    }

    .error-message.active {
        display: block;
        padding-left: 20px;
    }
}

@keyframes glow {
    0% {
        box-shadow: none;
    }

    100% {
        box-shadow: 0px -4px 0px -2px $color-functional-blue inset;
    }
}

@keyframes pop {
    0% {
        scale: 0.7;
    }

    30% {
        scale: 1.1;
    }

    100% {
        scale: 1.1;
    }
}
