/* =================================== */
/*  Link component                     */
/* =================================== */
a {
    font-family: "LMSans";
    touch-action: manipulation;
    text-decoration: none;
    text-transform: none;
    font-weight: 700;
    display: inline-flex;
    vertical-align: baseline;

    &,
    &:hover,
    &:focus,
    &:active {
        color: $color-blue-information-1;
    }
    
}