/* =================================== */
/*  Headings                           */
/* =================================== */
h1 {
    @include font-size($heading-size-1);
    margin-bottom: 0;
    text-align: center;
    text-transform: uppercase;
}

h2 {
    @include font-size($heading-size-2);
    margin-bottom: 0;
    text-align: center;
    text-transform: uppercase;
}

h3 {
    @include font-size($heading-size-3);
    margin-bottom: 0;
    text-align: center;
    text-transform: uppercase;
}

h4 {
    @include font-size($heading-size-4);
    margin-bottom: 0;
    text-align: center;
    text-transform: uppercase;
}

h5 {
    @include font-size($heading-size-5);
    margin-bottom: 0;
    text-align: center;
    text-transform: uppercase;
}

h6 {
    @include font-size($heading-size-6);
    margin-bottom: 0;
    text-align: center;
    text-transform: uppercase;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $text-color-headings;
    -webkit-font-smoothing: antialiased;
    font-family: $font-serif !important;
    text-align: left;
    text-transform: none;
    margin: 10px 0;
    font-family: inherit;
    line-height: 1.1;
}