/* =================================== */
/*  Basic list style                   */
/* =================================== */
li {

  >ul,
  >ol {
    margin-bottom: 0;
  }
}

ul {
  font-family: "LMSans";
  margin: 0;
  padding: 0;
  white-space: nowrap;
  letter-spacing: -5px;
  list-style: none;

  li {
    letter-spacing: normal;
    white-space: normal;
  }
}

/* =================================== */
/*  List component                     */
/* =================================== */
.list {
  position: relative;
  padding: 0;
  margin: 0;
  z-index: 999;
  min-width: 260px;

  ul {
    margin-top: -1px;
    width: 100%;
    padding: 0;
    list-style: none;
    background-color: $color-white;

    /* ================================================= */
    /*  List style Parameters                            */
    /* ================================================= */

    /* ===== Circle bullet ===== */
    &[data-icon-before]::before {
      padding: 0px;
      font-size: 0;
    }
  }

  li {
    padding: 11px 15px;

    &:has(> button) {
      padding: 0;
    }

    list-style-type: none;

    /* ================================================= */
    /*  List style Parameters                            */
    /* ================================================= */
    &.bullet::before {
      padding: 0px;
      font-size: 12px;
      margin-right: 8px;
      vertical-align: baseline;
    }

    /* ===== Odd even rows  ===== */
    &.even-odd:nth-child(2n) {
      background-color: $color-grey-almost-white;
    }

    /* ===== Border bottom  ===== */
    &.border-bottom {
      border-bottom: 1px solid $color-grey-light;
    }


    /* ===== Button component ===== */
    button {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-size: 0.9rem;
      padding: calc(#{$base} * 0.5) calc(#{$base} * 2);
      border: none !important;
      cursor: pointer;
      font-weight: normal;
      background-color: none;

      /* ===== Icon component ===== */
      i {
        padding-top: 5px;
      }

      &.secondary:hover,
      &.secondary:focus {
        background-color: $color-green-supporting-identity-1;
        color: $color-black;

        i {
          color: $color-green-primary;
        }
      }

      /* ===== Selected state ===== */
      &.selected {
        font-weight: bold;
      }

      i {
        color: $color-green-primary;
      }
    }
  }
}