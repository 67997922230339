.spinner {
    
    animation: rotate 2s linear infinite;
    width: 18px;
    height: 18px;
    margin-left: 10px;
    margin-bottom: -4px;

    & .path {
        stroke: $color-white;
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
    }

    /* ===== Small  ===== */
    &.small {
        width: 18px;
        height: 18px;

    }

    /* ===== Medium  ===== */
    &.medium {
        width: 25px;
        height: 25px;

    }

    /* ===== Large  ===== */
    &.large {
        width: 30px;
        height: 30px;
    }

    /* ===== Preview  ===== */
    &.preview {
        width: 60px;
        height: 60px;
    }
}

/* ===== color primary ===== */
.primary .spinner .path {
    stroke: $color-white;
}

/* ===== color hover secondary ===== */
.secondary.spinner .path {
    stroke: $color-green-primary;
}

.secondary .spinner .path {
    stroke: $color-green-primary;
}

/* ===== color disabled ===== */
.disabled .spinner .path {
    stroke: $color-grey-dark;
}

/* ===== color hover secondary ===== */
.secondary:hover.spinner .path {
    stroke: $color-white;
}