.datepicker {
    cursor: pointer;

    position: relative;
    width: 320px;

    input {
        width: 320px;
        z-index:998;
        cursor: pointer;
    }

    button.calendar-icon{
        position: absolute;
        top: 5px;
        right: -5px;
        z-index:9999;
        width:40px;
        height:40px;
        i {
            margin: 0 auto;
            padding:0;
            color: $color-grey;
        }
    }

    input[type="date"]::-webkit-inner-spin-button,
    input[type="date"]::-webkit-calendar-picker-indicator,
    input[type="time"]::-webkit-calendar-picker-indicator,
    input[type="datetime-local" i]::-webkit-calendar-picker-indicator,
    input[type="datetime-local" i]::-webkit-input-placeholder {
        background: none;
    }

    .calendar {
        z-index: 999;
    }



}