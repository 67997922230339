/* ===== Font Size - Mixin  ===== */  
@mixin font-size($font-size, $line-height: auto) {
    font-size: $font-size;
    font-size: rem($font-size) !important;
    font-weight: 700;

    @if $line-height == auto {
        line-height: ceil($font-size / $base-line-height) * ($base-line-height / $font-size);
    }

    @else {

        @if (type-of($line-height) == number or $line-height == inherit or $line-height == normal) {
            line-height: $line-height;
        }

        @else if ($line-height != none and $line-height != false) {
            @warn "\"#{$line-height}\" is not a valid value for \"line-height\".";
        }
    }
}
