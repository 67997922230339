/* ================================================= */
/*  Icon Component                                   */
/* ================================================= */
.material-icons {
    display: inline-flex;
    vertical-align: baseline;

    &.right {
        padding-left: 10px;
        float: right;
    }

    &.left {
        padding-left: 10px;
        float: left;
        width: 24px;
    }

    &.small {
        font-size: 16px;
    }

    &.medium {
        font-size: 20px;
    }

    &.large {
        font-size: 24px;
    }

    &.preview {
        font-size: 80px;
    }

    &.md-dark {
        color: $color-black;
    }

    &.md-dark.md-inactive {
        color: $color-grey-dark;
    }

    &.md-light {
        color: $color-white;
    }

    &.md-light.md-inactive {
        color: $color-grey;
    }

    &.md-vertical-align {
        vertical-align: middle;
    }

    &.required {
        color: $color-orange-information-2;
        position: relative;
        top: 3px;
        padding-right: 5px;
    }
}

[data-icon-after]::after,
[data-icon-before]::before {
    font-family: 'Material Icons';
    vertical-align: middle;
    font-size: 22px;
}

[data-icon-after]::after {
    content: attr(data-icon-after);
}

[data-icon-before]::before {
    content: attr(data-icon-before);
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}
