/* ================================================= */
/*  Button Component                                 */
/* ================================================= */
.button {
    font-family: 'LMSans';
    border: 0;
    cursor: pointer;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
    padding: calc(#{$base} * 0.7) calc(#{$base} * 3) calc(#{$base} * 0.8);
    text-align: left;
    line-height: 2;
    border-radius: 2px;

    /* ===== Primary button ===== */
    &.primary {
        color: $color-white;
        background-color: $color-green-supporting-identity-5;
        border: 2px solid $color-green-supporting-identity-5;

        i {
            color: $color-white;
            vertical-align: baseline;
        }

        &:hover {
            background-color: $color-green-supporting-identity-4;
            border-color: $color-green-supporting-identity-4;
        }
    }

    /* ===== Secondary button ===== */
    &.secondary {
        color: $color-black;
        background-color: transparent;
        border: 2px solid $color-green-supporting-identity-4;

        i {
            color: $color-green-supporting-identity-4;
            vertical-align: baseline;
        }

        &:hover {
            background: $color-green-supporting-identity-5;
            color: $color-white;

            i {
                color: $color-white;
            }
        }
    }

    /* ================================================= */
    /*  Button Parameters                                */
    /* ================================================= */

    /* ===== Disabled  ===== */
    &.disabled {
        border-color: $color-grey !important;
        background-color: $color-grey !important;
        color: $color-grey-dark;
        
        i {
            color: $color-grey-dark !important;
        }

        &:hover {
            background-color: $color-grey-light !important;
            border-color: $color-grey-light !important;
            color: $color-grey-darkest;
        }
    }

    /* ===== Delete button ===== */
    &.delete {
        color: $color-white;
        background-color: $color-red-information-2;
        border: 2px solid $color-red-information-2;

        i {
            color: $color-white;
            vertical-align: baseline;
        }

        &:hover {
            background-color: $color-red-information-1;
            border-color: $color-red-information-1;
        }
    }


    /* ===== Disabled  ===== */
    &.transparent {
        border: none !important;
        background: none !important;
        color: $color-grey-darkest;

        i {
            color: $color-grey-darkest;
        }

        &:hover {

            color: $color-functional-blue;

            i {
                color: $color-functional-blue;
            }
        }
    }

    /* ===== Delete buttons in table ===== */
    &.delete-table-row {
        border: none !important;
        background: none;
        padding:0px !important;

        i {
            font-size:26px !important;
            color: $color-red-information-2
        }

        &:hover {
            background:none;
            i {
                
                color: $color-red-information-1;
            }
        }
    }

    /* ===== Inverted  ===== */
    &.inverted {
        color: $color-white;
        
        &:hover {
            background: none;
            border-color: none;
        }

        &.hovered:hover {
            color: $color-white;
            background-color: $color-green-supporting-identity-4;
            border-color: $color-green-supporting-identity-4;
        }
        

        i {
            color: $color-white !important;
            margin-top:2px;
            &:hover {
                color: $color-white !important;
            }
        }
        
    }

    /* ===== Small  ===== */
    &.small {
        font-size: 13px;

        &.hasIcon {
            padding: calc(#{$base} * 1) calc(#{$base} * 2);
        }

        &.isTableHeader {
            padding: 0;
            font-size: 15px;
        }

        i {
            font-size: 16px;
            margin-left: -3px;
            padding-top: 5px;
        }
    }

    /* ===== Medium  ===== */
    &.medium {
        font-size: 16px;

        /*&.hasIcon {
      padding: calc(#{$base} * 0.8) calc(#{$base} * 3) calc(#{$base} * 0.7);
    }*/

        i {
            margin-left: -2px;
            font-size: 19px;
            padding-top: 7px;
        }
    }

    /* ===== Large  ===== */
    &.large {
        font-size: 20px;

        &.hasIcon {
            padding: calc(#{$base} * 2) calc(#{$base} * 3);
        }

        i {
            margin-left: -2px;
            font-size: 23px;
            padding-top: 10px;
            padding-right: 2px;
        }
    }

    /* ===== Full Column  ===== */
    &.fullcolumn {
        width: 100%;
    }

    &.right {
        float: right;
    }
}
