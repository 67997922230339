/* =============================================== */
/*  Font family variables and custom font faces.   */
/* =============================================== */
@font-face {
    font-family: "LMSans";
    src: url("../../fonts/LMSans/LMSans-Regular.eot");
    /* IE9 Compat Modes */
    src: url("../../fonts/LMSans/LMSans-Regular.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */
        url("../../fonts/LMSans/LMSans-Regular.woff2") format("woff2"),
        /* Super Modern Browsers */
        url("../../fonts/LMSans/LMSans-Regular.woff") format("woff"),
        /* Pretty Modern Browsers */
        url("../../fonts/LMSans/LMSans-Regular.ttf") format("truetype");
    /* Safari, Android, iOS */
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "LMSans";
    src: url("../../fonts/LMSans/LMSans-Medium.eot");
    /* IE9 Compat Modes */
    src: url("../../fonts/LMSans/LMSans-Medium.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */
        url("../../fonts/LMSans/LMSans-Medium.woff2") format("woff2"),
        /* Super Modern Browsers */
        url("../../fonts/LMSans/LMSans-Medium.woff") format("woff"),
        /* Pretty Modern Browsers */
        url("../../fonts/LMSans/LMSans-Medium.ttf") format("truetype");
    /* Safari, Android, iOS */
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "LMSans";
    src: url("../../fonts/LMSans/LMSans-Bold.eot");
    /* IE9 Compat Modes */
    src: url("../../fonts/LMSans/LMSans-Bold.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */
        url("../../fonts/LMSans/LMSans-Bold.woff2") format("woff2"),
        /* Super Modern Browsers */
        url("../../fonts/LMSans/LMSans-Bold.woff") format("woff"),
        /* Pretty Modern Browsers */
        url("../../fonts/LMSans/LMSans-Bold.ttf") format("truetype");
    /* Safari, Android, iOS */
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "LMSerif";
    src: url("../../fonts/LMSerif/LMSerif-Regular.eot");
    /* IE9 Compat Modes */
    src: url("../../fonts/LMSerif/LMSerif-Regular.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */
        url("../../fonts/LMSerif/LMSerif-Regular.woff2") format("woff2"),
        /* Super Modern Browsers */
        url("../../fonts/LMSerif/LMSerif-Regular.woff") format("woff"),
        /* Pretty Modern Browsers */
        url("../../fonts/LMSerif/LMSerif-Regular.ttf") format("truetype");
    /* Safari, Android, iOS */
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "LMSerif";
    src: url("../../fonts/LMSerif/LMSerif-Bold.eot");
    /* IE9 Compat Modes */
    src: url("../../fonts/LMSerif/LMSerif-Bold.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */
        url("../../fonts/LMSerif/LMSerif-Bold.woff2") format("woff2"),
        /* Super Modern Browsers */
        url("../../fonts/LMSerif/LMSerif-Bold.woff") format("woff"),
        /* Pretty Modern Browsers */
        url("../../fonts/LMSerif/LMSerif-Bold.ttf") format("truetype");
    /* Safari, Android, iOS */
    font-weight: 700;
    font-style: normal;
}