p, span, blockquote, button, input, textarea {
    font-family: "LMSans";
}

p {
    margin: 0;
}

p {
    margin-top: calc(#{$base} * 2);
}

label span {
    color: $color-grey-dark;
    margin-bottom: calc(#{$base}/2);
    letter-spacing: 0.05rem;    
}