.tooltip {
    display: flex;
    .tooltip-wrapper {
        position: relative;
        display: inline-flex;
        cursor: pointer;
    }

    &.is-hidden {
        .tooltip-container {
            display: none;
        }
    }

    &.is-visible {
        .tooltip-container {
            opacity: 1;
            display: block;
            animation-name: opacityAnimation;
            animation-duration: 0.2s;
        }
    }

    .tooltip-container {
        position: absolute;
        border-radius: 3px;
        z-index: 1000;
        width: max-content;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        max-width: 300px;
        background-color: $color-black;

        .tooltip-arrow {
            content: ' ';
            display: flex;
            position: absolute;
            bottom: 100%;
            left: 50%;
            margin-left: -10px;
            border-width: 10px;
            border-style: solid;
            border-color: transparent transparent $color-black transparent;
        }

        .tooltip-content {
            color: $color-white;
            padding: $base;
            text-align: center;
            white-space: pre-wrap;
            word-break: break-word;
        }
    }

    &.tooltip-overflow-right {
        .tooltip-container {
            left: 98%;
            transform: translateX(-98%);
            .tooltip__arrow {
                left: 92%;
            }
        }
    }

    &.tooltip-overflow-left {
        .tooltip-container {
            left: 2%;
            transform: translateX(2%);
            .tooltip__arrow {
                left: 4%;
            }
        }
    }

    &.position-top {
        .tooltip-container {
            margin: -10px 0 0 50px !important;
            top: 0;
            transform: translateY(-100%) translateX(-50%);

            .tooltip-arrow {
                bottom: unset;
                border-color: $color-black transparent transparent transparent;
            }
        }
    }

    &.position-right {
        .tooltip-container {
            left: 100%;
            top: 50%;
            transform: translateY(-50%);

            .tooltip-arrow {
                left: 1px;
                top: 50%;
                border-color: transparent $color-black transparent transparent;
                transform: translateY(-50%) translateX(-50%);
            }
        }
    }

    &.position-left {
        .tooltip-container {
            left: 0%;
            top: 50%;
            transform: translateY(-50%) translateX(-100%);

            .tooltip-arrow {
                margin-right: -1px;
                left: 100%;
                top: 50%;
                border-color: transparent transparent transparent $color-black;
                transform: translateY(-50%) translateX(50%);
            }
        }
    }

    &.error .tooltip-container {
        background: $color-red-information-2 !important;
    }

    &.error.position-top .tooltip-arrow {
        border-color: $color-red-information-2 transparent transparent
            transparent !important;
    }

    &.error.position-bottom .tooltip-arrow {
        border-color: transparent transparent $color-red-information-2
            transparent !important;
    }

    &.error.position-left .tooltip-arrow {
        border-color: transparent transparent transparent
            $color-red-information-2 !important;
    }

    &.error.position-right .tooltip-arrow {
        border-color: transparent $color-red-information-2 transparent
            transparent !important;
    }

   
    &.information .tooltip-container {
        background: $color-black !important;
    }

    &.information.position-top .tooltip-arrow {
        border-color: $color-black transparent transparent transparent !important;
    }
    &.information.position-bottom .tooltip-arrow {
        border-color: transparent transparent $color-black transparent !important;
    }
    &.information.position-left .tooltip-arrow {
        border-color: transparent transparent transparent $color-black !important;
    }
    &.information.position-right .tooltip-arrow {
        border-color: transparent $color-black transparent transparent !important;
    }
}

@keyframes opacityAnimation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
